// This file handles storing the current URL when a login or register button is clicked
// and redirects the user to the stored URL after login or registration.
(() => {
  const redirectAfterLogin = '_ral'; // stands for redirectAfterLogin
  const queryParamKey = 'd';

  // Store the current URL in local storage
  const storeCurrentUrl = () => {
    try {
      const url = new URL(window.location.href);
      const curTimeStamp = new Date().getTime();
      url.searchParams.set(queryParamKey, curTimeStamp + '');
      localStorage.setItem(redirectAfterLogin, url.href);
    } catch {
      localStorage.setItem(redirectAfterLogin, window.location.href);
    }
  };

  // Redirect to the stored URL from local storage, if it exists
  const redirectToStoredUrl = () => {
    const redirectUrl = localStorage.getItem(redirectAfterLogin);
    if (redirectUrl) {
      localStorage.removeItem(redirectAfterLogin);
      window.location.href = redirectUrl;
    }
  };

  // Set up event listeners on login and register buttons to store the current URL
  const checkRedirectButtons = () => {
    const buttons = document.querySelectorAll(
      '.header__btn--login, .header__btn--reg'
    );
    buttons.forEach(button =>
      button.addEventListener('click', storeCurrentUrl)
    );
  };

  // Check if the document is still loading or already loaded, and run init accordingly
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', checkRedirectButtons);
  } else {
    checkRedirectButtons();
  }

  redirectToStoredUrl();
})();
