/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/naming-convention, import/extensions, no-restricted-properties, no-underscore-dangle, max-len, max-lines */

// Stylesheets
import './main.scss';

import './components/**/*.scss';

import './components/**/*.ts';

import './redirect';
declare global {
  interface Window {
    Bus: any; // event bus
    OneTrust: any;
    _tnsInstances: any; // carousel instances
    _tabbyInstances: any; // tabset instances
    _badgerInstances: any; // badger accordion instances
    Plyr: any;
    dataLayer: any;
    grecaptcha: any;
  }
}

(() => {
  const debounce = (func, delay = 400) => {
    let debounceTimer;
    // eslint-disable-next-line func-names
    return function () {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const context = this;
      // eslint-disable-next-line prefer-rest-params
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  // initializes logoCarousel on approved home page
  // makes sure that the carousel loop and autoplay are paused when there are less no. of items in the carouse
  const initLogoCarousel = () => {
    const mainCarouselInst = window?._tnsInstances?.['logo-carousel'];
    const carouselEl = document.querySelector('#logo-carousel');
    const carouselContainer = carouselEl?.closest(
      '.home-logo-carousel__container'
    );
    let winWidth = window.innerWidth;
    if (mainCarouselInst && carouselContainer) {
      const mainCarouselInfo = mainCarouselInst.getInfo();
      const { slideItems, slideCount } = mainCarouselInfo;
      // eslint-disable-next-line no-unsafe-optional-chaining
      const mainCarouselItemsWidth = slideCount * slideItems[0]?.clientWidth;

      if (slideCount <= 3) {
        carouselEl?.classList.add('has-3-or-less-slides');
      }

      const checkAndAddLessSlidesClass = () => {
        const carouselInst = window._tnsInstances['logo-carousel'];
        if (mainCarouselItemsWidth > carouselContainer.clientWidth) {
          carouselEl?.classList.remove('has-less-slides');
          carouselInst.destroy();
          window._tnsInstances['logo-carousel'] = carouselInst.rebuild();
          window._tnsInstances['logo-carousel'].play();
        } else {
          carouselInst.destroy();
          window._tnsInstances['logo-carousel'] = carouselInst.rebuild();
          carouselEl?.classList.add('has-less-slides');
          window._tnsInstances['logo-carousel'].pause();
        }
      };

      checkAndAddLessSlidesClass();
      window.addEventListener(
        'resize',
        debounce(() => {
          const curWidth = window.innerWidth;
          if (winWidth !== curWidth) {
            winWidth = curWidth;
            checkAndAddLessSlidesClass();
          }
        }, 400)
      );
    }
  };

  // when the download button in resources listing and resource details page are clicked, a modal with terms and conditions should be shown.
  // After accepting the terms and conditions by clicking the checkbox and then submitting the form should initiate file download
  // The terms and conditions checkbox should decide the disabled state of the submit button present in the modal
  const handleResourceDownloads = () => {
    const resDownloadTriggers = document.querySelectorAll(
      '.resources-download-trigger'
    );
    const modal = document.querySelector(
      '[data-id="resource-download-modal"] .resource-download-modal-container'
    ) as HTMLElement;
    const acceptTermsCheckbox = modal?.querySelector(
      'input[type="checkbox"]'
    ) as HTMLFormElement;
    const downloadBtn = modal?.querySelector(
      '.resource-download-modal__cta'
    ) as HTMLButtonElement;
    const modalClose = modal
      ?.closest('.modal')
      ?.querySelector('.main-close') as HTMLButtonElement;

    if (modal && resDownloadTriggers?.length) {
      // when download trigger is clicked, adding the href as a data attr to the modal.
      resDownloadTriggers.forEach(trigger => {
        trigger.addEventListener('click', () => {
          modal.setAttribute(
            'data-href',
            trigger.getAttribute('data-href') || ''
          );

          modal.setAttribute(
            'data-title',
            trigger.getAttribute('data-title') || ''
          );

          // making sure that the checkbox and the download button are in the right state when modal opens
          if (downloadBtn) {
            downloadBtn.disabled = true;
          }

          if (acceptTermsCheckbox) {
            acceptTermsCheckbox.checked = false;
          }
        });
      });

      // when checkbox is checked, download button should be enabled. Else should not
      if (acceptTermsCheckbox) {
        acceptTermsCheckbox.addEventListener('change', () => {
          downloadBtn.disabled = !acceptTermsCheckbox.checked;
        });
      }

      // when download button inside the modal is clicked, creating an anchor and clicking that with JS to download the resource
      if (modalClose && downloadBtn) {
        downloadBtn.addEventListener('click', () => {
          const resLink = modal.getAttribute('data-href');

          if (resLink) {
            const tempAnchor = document.createElement('a');
            tempAnchor.setAttribute('href', resLink);
            tempAnchor.click();
            tempAnchor.remove();
            modalClose.click();

            // adding data to data layer for download
            const resourceTitle = modal.getAttribute('data-title');
            if (resourceTitle) {
              window.dataLayer = window.dataLayer ? window.dataLayer : [];
              window.dataLayer.push({
                event: 'downloadResource',
                resourceTitle,
              });
            }
          }
        });
      }
    }
  };

  // handles click event of the logout link. Makes an API call when clicked
  const handleLogout = () => {
    const logoutCtas = document.querySelectorAll(
      '.logout-action'
    ) as NodeListOf<HTMLAnchorElement>;
    const pagePath = document
      .querySelector('.app-container')
      ?.getAttribute('data-page-path');
    let autoLogoutIntervalTimer;

    if (pagePath && logoutCtas?.length) {
      logoutCtas.forEach(el => {
        const href = el.getAttribute('href');
        if (href) {
          el.addEventListener('click', e => {
            e.preventDefault();
            const queryParams = new URLSearchParams({
              path: pagePath,
            });
            // get call to this endpoint will make a call
            // server removes the cookie related to AEM Login
            // as soon as this call responds with a 200 status, user can be redirected to the ICC page
            fetch(
              `/content/aa-ous/services/logout.api.json?${queryParams}`
            ).then(data => {
              if (data?.status === 200) {
                window.location.href = href;
              }
            });
          });

          // automatically clicking the logout link if it has a data-duration attribute
          // useful for error pages, where the data for user is not available and user must be forcefully logged out
          const autoLogoutDuration = el.getAttribute('data-duration');

          if (autoLogoutDuration) {
            clearInterval(autoLogoutIntervalTimer);
            const intDuration = parseInt(autoLogoutDuration, 10);
            const duration = !Number.isNaN(intDuration) ? intDuration : 5;
            const timerText = el
              .closest('.error-container')
              ?.querySelector('.timeout-text .emphasis') as HTMLSpanElement;

            let timerCounter = 0;
            const intervalTimer = () => {
              autoLogoutIntervalTimer = setTimeout(() => {
                if (timerCounter < duration) {
                  if (timerText) {
                    timerText.innerText = `${duration - timerCounter}`;
                  }
                  timerCounter += 1;
                  intervalTimer();
                } else {
                  el.click?.();
                  clearTimeout(autoLogoutIntervalTimer);
                }
              }, 1000);
            };

            intervalTimer();
          }
        }
      });
    }
  };

  // on page load, because the content inside the teaser is positioned as absolute, and the image takes a bit of time to load, the page shows distorted until the image is loaded.
  // until the teaser image is loaded, hiding the teaser content, and then showing it with a subtle animation
  const handleTeaserLoad = () => {
    // teaser-image-container
    const teasers = document.querySelectorAll(
      '.teaser'
    ) as NodeListOf<HTMLElement>;
    const winWidth = window.innerWidth;

    if (teasers?.length) {
      teasers.forEach(el => {
        const teaserImgContainer = el.querySelector('.teaser-image-container');
        const teaserImg = (
          winWidth < 768
            ? teaserImgContainer?.querySelector(
                '.cmp-teaser__image--mobile img'
              )
            : teaserImgContainer?.querySelector('.cmp-teaser__image img')
        ) as HTMLImageElement;
        if (teaserImg) {
          if (teaserImg.complete && teaserImg.naturalWidth) {
            el?.classList.add('teaser--img-loaded');
          } else {
            teaserImg.addEventListener('load', () => {
              el?.classList.add('teaser--img-loaded');
            });
          }
        } else {
          el?.classList.add('teaser--img-loaded');
        }
      });
    }
  };

  // empty div gets added for anonymous user which does not contain links. That must be displayed none.
  // same for logged-in user too
  const displayFooterLinks = () => {
    const footerSection = document
      .querySelector('.footer__links-section')
      ?.closest('.experiencefragment') as HTMLElement;
    footerSection?.classList.add('show');
  };

  // it will handle click event on header links
  const handleHeaderLinks = () => {
    const headerLinks = document.querySelectorAll(
      '.header__global-navigation ul li a'
    ) as NodeList;
    headerLinks.forEach(links => {
      links.addEventListener('click', () => {
        const headerTitle = links.textContent;
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'globalNavigation',
          clickText: headerTitle,
        });
      });
    });
  };

  const addDataLayerForFooter = title => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'footerNavigation',
      clickText: title,
    });
  };

  // it will handle click event on footer links
  const handleFooterLinks = () => {
    const footerLinks = document.querySelectorAll(
      '.footer__links-section li a'
    ) as NodeList;
    const footerSocialLink = document.querySelector(
      '.footer__links-section--follow a'
    ) as HTMLElement;

    if (footerLinks.length > 0) {
      footerLinks.forEach(links => {
        links?.addEventListener('click', () => {
          const footerTitle = links.textContent;
          addDataLayerForFooter(footerTitle);
        });
      });
    }
    footerSocialLink?.addEventListener('click', () => {
      const instaTitle = 'Instagram';
      addDataLayerForFooter(instaTitle);
    });
  };

  // it will handle lazyload of images
  const handleLazyLoad = () => {
    var lazyImages = document.querySelectorAll('.lazy-load__img');
    const lazyImageObserver = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const lazyImage = entry.target as HTMLInputElement;
            lazyImage.src =
              lazyImage.dataset.src !== undefined ? lazyImage.dataset.src : '';
            lazyImage.classList.add('lazy-load__img--loaded');
            lazyImageObserver.unobserve(lazyImage);
          }
        });
      },
      {
        threshold: 0.5,
      }
    );
    lazyImages.forEach(lazyImage => {
      lazyImageObserver.observe(lazyImage);
    });
  };

  // Handles the toggling of events and courses tabs in the AMI Component
  const handleTabs = () => {
    const tabComponents = document.querySelectorAll('.aaous-tabs__wrapper');

    const appendTabEvents = wrapper => {
      const tabTriggers = wrapper?.querySelectorAll('.aaous-tabs__trigger a');
      const tabContents = wrapper?.querySelectorAll('.aaous-tabs__content');
      const tabContentsWrapper = wrapper?.querySelector(
        '.aaous-tabs__contents-wrapper'
      );

      const setTabContentWrapperHeight = () => {
        if (tabContentsWrapper && tabContents?.length) {
          tabContents.forEach(tabContent => {
            if (tabContent.classList.contains('active')) {
              tabContentsWrapper.style.height = `${tabContent.scrollHeight}px`;
            }
          });
        }
      };

      const flagEl = wrapper;
      flagEl.adjustTabHeights = setTabContentWrapperHeight;

      if (tabTriggers?.length) {
        tabTriggers[0]?.classList?.add('active');
        tabContents[0]?.classList?.add('active');
        setTabContentWrapperHeight();

        let winWidth = window.innerWidth;
        window.addEventListener('resize', () => {
          const curWidth = window.innerWidth;
          if (winWidth !== curWidth) {
            winWidth = curWidth;
            setTabContentWrapperHeight();
          }
        });

        tabTriggers.forEach(trigger => {
          trigger.addEventListener('click', e => {
            e.preventDefault();

            // return if the tab is already active
            if (trigger.classList.contains('active')) {
              return;
            }

            const target = trigger.getAttribute('data-tab-target');

            // removing existing active tab, and adding active to current Tab
            tabTriggers.forEach(curTab => {
              const tabTarget = curTab.getAttribute('data-tab-target');
              if (tabTarget === target) {
                curTab.classList.add('active');
              } else {
                curTab.classList.remove('active');
              }
            });

            let curActive;
            let nextActive;
            // adding active class to the corresponding tab content
            tabContents.forEach(tabContent => {
              if (tabContent.classList.contains('active')) {
                curActive = tabContent;
              }

              if (tabContent.getAttribute('data-tab-content') === target) {
                nextActive = tabContent;
              }
            });

            if (curActive && nextActive) {
              curActive.classList.remove('active');
              nextActive.classList.add('active');
              setTabContentWrapperHeight();
            }
          });
        });
      }
    };

    if (tabComponents?.length) {
      tabComponents.forEach(tabWrapper => {
        appendTabEvents(tabWrapper);
      });
    }
  };

  // handler to handle the callbacks when a modal is closed
  const handleModalClose = () => {
    window.Bus?.on('emu-modal:close', e => {
      const { id } = e;
      const modal = document.querySelector(
        `[data-component="modal"][data-id="${id}"]`
      ) as HTMLElement;

      const form = modal?.querySelector('.cmp-ajax-form') as HTMLFormElement;

      // removing success and fail classes to the form when the modal is closed
      const formContainer = form?.closest('.ajaxContainer');
      formContainer?.classList.remove(
        'js-response-success',
        'js-response-errors'
      );
    });
  };

  const initiatePage = () => {
    // Global JS
    if (document.body) {
      document.body.style.visibility = 'visible';
    }

    // initialize Logo Carousel in home approved user page
    initLogoCarousel();

    // download button related code for resource listing and resource download page
    handleResourceDownloads();

    // handle logout related code
    handleLogout();

    // handle teaser load
    handleTeaserLoad();

    // display footer links
    displayFooterLinks();

    // handle header link click event
    handleHeaderLinks();

    // handle footer link click event
    handleFooterLinks();

    // it will handle lazyload of images
    handleLazyLoad();

    // handle custom tabs component
    handleTabs();

    // handle modal close
    handleModalClose();
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', initiatePage);
  } else {
    initiatePage();
  }

  window.addEventListener('load', () => {
    document.body.classList.add('page-loaded');
  });
})();
