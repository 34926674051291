/**
 * Issue Description:
 * AAAEM Common lacks a built-in mechanism for validating mandatory checkboxes, causing issues with form submissions.
 * This script simulates the intended functionality by displaying an error message when the form is submitted without checking mandatory checkboxes.
 *
 * Implementation Details:
 * To enable this functionality, ensure the following authoring hierarchy:
 * - Ajax Form Container
 *   > Container with the class "mandatory-checkbox"
 *     > Form Options (with "required" selected in the dialog)
 * - Form Text (Sibling to the container with the "mandatory-checkbox" class, also marked as "required")
 *
 * Note:
 * Input (Form Text) validations work seamlessly with AAAEM Common out of the box.
 * The script updates the input text element's value with checkbox values whenever their state changes, triggering the validation process.
 * Additionally, a mutation observer is added on the input text element to detect the presence of the error class and apply it to the corresponding checkbox.
 */
(() => {
  const handleMandatoryCheckbox = checkboxWrapper => {
    const checkboxes = checkboxWrapper?.querySelectorAll(
      'input[type="checkbox"]'
    ) as NodeListOf<HTMLInputElement>;

    // element storing the values of the checkbox
    const inputStoreEl = checkboxWrapper?.closest('.container')
      ?.nextElementSibling as HTMLElement;
    const trackerEl = inputStoreEl?.classList.contains('text')
      ? inputStoreEl.querySelector('.cmp-form-text__text')
      : null;

    let checkedValues: Array<string> = [];

    // update the checked values as values of the tracker element
    const updatedCheckedValues = checkbox => {
      const { value, checked } = checkbox;

      if (checked) {
        checkedValues.push(value);
      } else {
        checkedValues = checkedValues.filter(item => {
          return item !== value;
        });
      }

      if (trackerEl && trackerEl instanceof HTMLInputElement) {
        trackerEl.value = checkedValues.join(',');
        trackerEl.dispatchEvent(new Event('change'));
      }
    };

    if (checkboxes?.length && trackerEl) {
      checkboxes.forEach(checkbox => {
        checkbox.addEventListener('change', () => {
          updatedCheckedValues(checkbox);
        });
      });

      // observe when error class gets added to the tracker element, and add error class to the checkbox container too
      const observer = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
          if (mutation.attributeName === 'class') {
            checkboxWrapper.classList.toggle(
              'has-error',
              trackerEl.classList.contains('error')
            );
          }
        });
      });

      observer.observe(trackerEl, {
        attributes: true,
        attributeFilter: ['class'],
      });
    }
  };

  // initializing checkboxes
  const initCheckbox = () => {
    // eslint-disable-next-line no-restricted-properties
    const checkboxWrappers = document.querySelectorAll('.mandatory-checkbox');

    if (checkboxWrappers?.length) {
      checkboxWrappers.forEach(checkboxWrapper => {
        handleMandatoryCheckbox(checkboxWrapper);
      });
    }
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', initCheckbox);
  } else {
    initCheckbox();
  }
})();
