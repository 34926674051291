/* eslint-disable @typescript-eslint/naming-convention, import/extensions, no-restricted-properties, no-underscore-dangle, max-len, no-console, default-param-last, max-lines */

(() => {
  const processAMIComponent = amiEl => {
    const dataSetEl = amiEl.querySelector(
      '.ami__content-details'
    ) as HTMLElement;

    // constants used across the file
    const CONSTANTS: {
      classes: any;
      contentDataSet: any;
      loader: any;
    } = {
      classes: {
        showLoader: 'loader--active',
        noCoursesSelector: '.ami__nocontent--course',
        noEventsSelector: '.ami__nocontent--event',
        showNoEventsOrClasses: 'ami__nocontent--show',
        eventContainerSelector: '.event-container',
        courseContainerSelector: '.course-container',
        noUserSelector: '.ami__nocontent--user',
        amiRegisteredUserBtn: '.ami__btn--registered',
        amiUnRegisteredUserBtn: '.ami__btn--unregistered',
        tabSelector: '.aaous-tabs__triggers-wrapper',
        disableEventsOrCourses: 'ami__tab--disable',
      },
      contentDataSet: dataSetEl?.dataset,
      loader: amiEl.querySelector('.ami__loader'),
    };

    // shows no content for events tab / courses tab
    const showNoContent = (type: 'event' | 'course' | 'user') => {
      let noContentEl = document.querySelector(
        CONSTANTS.classes.noEventsSelector
      );
      if (type === 'course') {
        noContentEl = document.querySelector(
          CONSTANTS.classes.noCoursesSelector
        );
      }
      if (type === 'user') {
        noContentEl = document.querySelector(CONSTANTS.classes.noUserSelector);
        const tabWrapperSelector = document.querySelector(
          CONSTANTS.classes.tabSelector
        );
        tabWrapperSelector?.classList?.add(
          CONSTANTS.classes.disableEventsOrCourses
        );
      }

      noContentEl?.classList?.add(CONSTANTS.classes.showNoEventsOrClasses);
    };

    // creates and returns element for a provided type, classes (array of classes), innerHTML
    const createEl = (
      type = 'div',
      classes?: Array<string>,
      innerHTML?: string
    ): HTMLElement => {
      const el = document.createElement(type);
      if (classes && classes instanceof Array) {
        el.classList.add(...classes);
      }

      if (innerHTML) {
        el.innerHTML = innerHTML;
      }

      return el;
    };

    // display button based on AMI user
    const showAMIBtn = isAMIUser => {
      if (isAMIUser) {
        document
          .querySelector(CONSTANTS.classes.amiRegisteredUserBtn)
          ?.classList.add('ami__btn--show');
      } else {
        document
          .querySelector(CONSTANTS.classes.amiUnRegisteredUserBtn)
          ?.classList.add('ami__btn--show');
      }
    };

    // returns a string which is to be shown inside AMI Component Events CTAs
    // Not AMI User - Register
    // AMI User and Catalog Event - Sign Up
    // AMI User and not catalog event - Details
    const getEventDetailsCTAText = (isAMIUser, isCatalogEvent) => {
      if (!isAMIUser) {
        // register
        return CONSTANTS.contentDataSet.amiBtnTxtRegister;
      }
      if (isCatalogEvent) {
        // sign up
        return CONSTANTS.contentDataSet.amiBtnTxtSignup;
      }
      // details
      return CONSTANTS.contentDataSet.amiBtnTxtDetails;
    };

    // returns a string which is to be shown inside AMI Component Course's CTAs
    // Not AMI User - Register
    // AMI User and Catalog Course - Enroll
    // AMI User and not catalog course - progress > 0 - Resume
    // AMI User and not catalog course - progress <= 0 - Start
    const getCourseDetailsCTAText = (isAMIUser, isCatalogCourse, progress) => {
      if (!isAMIUser) {
        // register
        return CONSTANTS.contentDataSet.amiBtnTxtRegister;
      }
      if (isCatalogCourse) {
        // enroll
        return CONSTANTS.contentDataSet.amiBtnTxtEnroll;
      }

      if (progress && progress > 0) {
        // resume
        return CONSTANTS.contentDataSet.amiBtnTxtResume;
      }

      // start
      return CONSTANTS.contentDataSet.amiBtnTxtStart;
    };

    // Constructs and returns event related HTML Element for a given event and whether the user is an AMI User
    const createEventElement = (event, isAMIUser) => {
      const {
        courseName: eventName,
        fromCatalog: isCatalogEvent,
        detailsURL: eventDetailUrl,
        dateStarted: eventDate,
      } = event;

      const eventWrapDiv = createEl('div', [
        'ami__list-card',
        'ami__list-card--event',
      ]);

      // main content
      const eventContent = createEl('div', ['ami__list-card-content']);

      // cta wrapper
      const eventAction = createEl('div', ['ami__list-card-action-wrapper']);

      // icon
      const eventIcon = createEl('div', ['ami__list-card-icon']);

      // date
      const eventListDate = createEl(
        'span',
        ['ami__list-card-date'],
        `${eventDate}:`
      );

      // title
      const eventTitle = createEl('div', ['ami__list-card-title'], eventName);

      // pre title
      const eventPreTitle = createEl(
        'p',
        ['ami__list-card-pretitle'],
        CONSTANTS.contentDataSet.amiEventTitle
      );

      // action cta
      const eventActionUrl = createEl(
        'a',
        ['ami__cta', 'aaaem-button', 'aaaem-button__primary-filled'],
        getEventDetailsCTAText(isAMIUser, isCatalogEvent)
      );
      eventActionUrl.setAttribute('href', eventDetailUrl);
      eventActionUrl.setAttribute(
        'target',
        CONSTANTS.contentDataSet.amiEventBtnTarget
      );

      if (isAMIUser) {
        eventActionUrl.classList.remove('aaaem-button__primary-filled');
        eventActionUrl.classList.add('aaaem-button__primary-outline');
        eventActionUrl.classList.add('ami__cta--white-bg');
      }

      // adding pre title and title
      eventIcon.append(eventPreTitle);
      eventContent.append(eventIcon);
      eventContent.append(eventTitle);
      eventTitle.prepend(eventListDate);

      // adding registered tag to the event
      if (isAMIUser && isCatalogEvent !== true) {
        const eventType = createEl(
          'p',
          ['ami__list-card-type'],
          CONSTANTS.contentDataSet.amiBtnTxtRegistered
        );
        eventContent.append(eventType);
      }

      // adding anchor to action cta wrapper
      eventAction.append(eventActionUrl);

      // adding icon and content to the main div
      eventWrapDiv.append(eventContent);
      eventWrapDiv.append(eventAction);

      return eventWrapDiv;
    };

    // Constructs and returns course related HTML Element for a given course and whether the user is an AMI User
    const createCourseElement = (course, isAMIUser) => {
      const {
        courseName,
        description,
        fromCatalog: isCatalogCourse,
        progress,
        detailsURL: courseDetailUrl,
      } = course;

      const courseWrapDiv = createEl('div', [
        'ami__list-card',
        'ami__list-card--course',
      ]);

      const progressVal = Math.min(100, Math.trunc(Math.ceil(progress || 0)));

      const courseContent = createEl('div', ['ami__list-card-content']);
      const courseAction = createEl('div', ['ami__list-card-action-wrapper']);
      const courseIcon = createEl('div', ['ami__list-card-icon']);
      const courseTitle = createEl('p', ['ami__list-card-title'], courseName);
      const courseActionUrl = createEl(
        'a',
        ['ami__cta', 'aaaem-button', 'aaaem-button__primary-filled'],
        getCourseDetailsCTAText(
          isAMIUser,
          isCatalogCourse,
          progress ? progressVal : false
        )
      );
      courseActionUrl.setAttribute('href', courseDetailUrl);
      courseActionUrl.setAttribute(
        'target',
        CONSTANTS.contentDataSet.amiCourseBtnTarget
      );
      // adding title to icon, and then to content
      courseIcon.append(courseTitle);
      courseContent.append(courseIcon);

      // adding course description if progress is not present on a course, else showing progress bar
      if (isAMIUser && isCatalogCourse !== true && progress && progress > 0) {
        const courseProgressBar = createEl(
          'p',
          ['ami__list-card-progress'],
          `${progressVal}% Complete`
        );
        const courseWrapper = createEl('div', [
          'ami__list-card-progress-wrapper',
        ]);
        const courseData = createEl('div', ['ami__list-card-progress-data']);

        // showing the width of progress bar
        courseData.style.width = `${progressVal}%`;

        courseContent.append(courseProgressBar);
        courseWrapper.append(courseData);
        courseContent.append(courseWrapper);
      } else {
        const courseText = createEl(
          'div',
          ['ami__list-card-text'],
          description
        );
        courseContent.append(courseText);
      }

      // adding CTA to cta wrapper
      courseAction.append(courseActionUrl);

      // adding content and action to main wrapper
      courseWrapDiv.append(courseContent);
      courseWrapDiv.append(courseAction);

      return courseWrapDiv;
    };

    // for given set of data and isAMIUser params, constructs HTML for the events/courses and appends to the corresponding tab
    const constructAMIEventsAndCourses = (
      data,
      isAMIUser,
      type: 'event' | 'course'
    ) => {
      const wrapper = amiEl.querySelector(
        type === 'event'
          ? CONSTANTS.classes.eventContainerSelector
          : CONSTANTS.classes.courseContainerSelector
      );

      if (wrapper) {
        const docFrag = new DocumentFragment();

        data.forEach(dataItem => {
          let wrapDiv;
          if (type === 'event') {
            wrapDiv = createEventElement(dataItem, isAMIUser);
          } else if (type === 'course') {
            wrapDiv = createCourseElement(dataItem, isAMIUser);
          }

          if (wrapDiv) {
            docFrag.append(wrapDiv);
          }
        });

        wrapper?.appendChild(docFrag);
      }
    };

    // Error handling when there is no AMI Data
    const handleAMIError = () => {
      showNoContent('event');
      showNoContent('course');
    };

    // makes a fetch call to get the AMI data of a user
    // creates a HTML structure and injects into the tabs of AMI Component
    const loadAMIData = () => {
      const amiLoader = CONSTANTS.loader;
      amiLoader?.classList.add(CONSTANTS.classes.showLoader);

      const amiEndPointDataUrl = CONSTANTS.contentDataSet?.amiEndpoint;
      if (amiEndPointDataUrl) {
        fetch(amiEndPointDataUrl)
          .then(
            response => {
              try {
                return response.json();
              } catch (error) {
                console.warn(error);
                return null;
              }
            },
            () => {
              handleAMIError();
              // handle API Error
            }
          )
          .then(rawData => {
            if (rawData) {
              const {
                hasAMIConnection: isAMIUser,
                noEventsTab: hasNoEvents,
                noCoursesTab: hasNoCourses,

                events,
                courses,
              } = rawData;

              // populating events/courses for AMI user
              showAMIBtn(isAMIUser);
              if (isAMIUser) {
                // populating events / no event content
                if (events?.length) {
                  constructAMIEventsAndCourses(events, isAMIUser, 'event');
                } else if (hasNoEvents || events?.length === 0) {
                  showNoContent('event');
                }

                // populating courses / no course content
                if (courses?.length) {
                  constructAMIEventsAndCourses(courses, isAMIUser, 'course');
                } else if (hasNoCourses || courses?.length === 0) {
                  showNoContent('course');
                }
              } else {
                showNoContent('user');
              }
            } else {
              handleAMIError();
            }
          })
          .catch(error => {
            console.warn('Failed to fetch page:', error);
            handleAMIError();
          })
          .finally(() => {
            amiLoader?.classList.remove(CONSTANTS.classes.showLoader);
            amiLoader?.closest('.aaous-tabs__wrapper')?.adjustTabHeights?.();
          });
      }
    };

    if (CONSTANTS.contentDataSet?.amiEndpoint) {
      // loading AMI data into the component
      loadAMIData();
    }
  };

  // initializing the AMI component JS
  const initiateAMI = () => {
    const amiEls = document.querySelectorAll(
      '.ami__container'
    ) as NodeListOf<HTMLElement>;

    if (amiEls?.length) {
      amiEls.forEach(amiEl => {
        processAMIComponent(amiEl);
      });
    }
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', initiateAMI);
  } else {
    initiateAMI();
  }
})();
