(() => {
  const handleMenuClick = () => {
    const menuButton = document.querySelector(
      '.header__menu-trigger'
    ) as HTMLButtonElement;

    const closeMenuButton = document.querySelector(
      '.aaaem-navigation__global-navigation-close-button button'
    ) as HTMLButtonElement;

    const toggleMenu = () => {
      document.body?.classList.toggle('has-menu-open');
    };

    menuButton?.addEventListener('click', toggleMenu);
    closeMenuButton?.addEventListener('click', toggleMenu);
  };

  const makeHeaderSticky = () => {
    const elem = document.querySelector(
      '#menu-sticky-placeholder'
    ) as HTMLElement;

    if (elem) {
      // observer that observes whether the element is on screen and adds src to the iframe when it is on screen
      const observer = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              document.body.classList.remove('has-fixed-header');
            } else {
              document.body.classList.add('has-fixed-header');
            }
          });
        },
        {
          threshold: 0.5,
        }
      );
      observer.observe(elem);
    }
  };

  // handler for all events in header
  const headerInit = () => {
    handleMenuClick();

    window.addEventListener('scroll', makeHeaderSticky);
    makeHeaderSticky();
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', headerInit);
  } else {
    headerInit();
  }
})();
