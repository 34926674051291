const MobileTabs = function (elem) {
  // @ts-ignore
  const self = this;

  self.updateActiveTextToRepTab = () => {
    const activeTabTrigger = self.tabTriggersWrapper?.querySelector(
      'li a[aria-selected="true"]'
    );
    if (activeTabTrigger) {
      self.repTabTextEl.innerText = activeTabTrigger.innerText || '';
    }
  };

  // appends an empty item on the top of tab triggers
  self.appendRepresentationalTab = () => {
    const listItem = document.createElement('li');
    const anchor = document.createElement('a');
    const arrowSpan = document.createElement('span');
    const textSpan = document.createElement('span');
    textSpan.classList.add(self.classnames.repText);
    arrowSpan.classList.add(self.classnames.repArrow);
    anchor.setAttribute('href', '#');
    anchor.append(textSpan);
    anchor.append(arrowSpan);
    listItem.append(anchor);
    listItem.classList.add(self.classnames.tabsTrigger);
    listItem.classList.add(self.classnames.repTab);
    self.tabTriggersWrapper?.insertBefore(
      listItem,
      self.tabTriggersWrapper.firstChild
    );
    self.repTabEl = listItem;
    self.repTabTextEl = textSpan;
    self.updateActiveTextToRepTab();
  };

  // when tab is clicked, toggle a class on the wrapper and updated the placeholder text with the active tab trigger text
  self.tabClicked = () => {
    self.tabTriggersWrapper.classList.toggle(self.classnames.open);
    self.updateActiveTextToRepTab();
  };

  // append events
  self.appendEvents = () => {
    if (self.repTabEl) {
      self.tabTriggersWrapper.addEventListener('click', self.tabClicked);
    }
  };

  // init variables being used across the file
  self.initVariables = () => {
    self.elem = elem;
    self.tabTriggersWrapper = self.elem?.querySelector('.aaaem-tabs__tablist');
    self.classnames = {
      open: 'aaaem-tabs__tablist--open',
      repTab: 'aaaem-tabs__representational-tab',
      tabsTrigger: 'aaaem-tabs__tab',
      repArrow: 'aaaem-tabs__representational-arrow',
      repText: 'aaaem-tabs__representational-text',
    };
  };

  const init = () => {
    self.initVariables();
    self.appendRepresentationalTab();
    self.appendEvents();
  };

  init();
};

const initMobileTabs = function () {
  const tabs = document.querySelectorAll('.aaaem-tabs.aaaem-tabs__vertical-ui');

  if (tabs?.length) {
    Array.from(tabs).forEach(tab => {
      new MobileTabs(tab);
    });
  }
};

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', initMobileTabs);
} else {
  initMobileTabs();
}
