const _temp0 = require("./components/ami/ami.ts");

const _temp1 = require("./components/customersupport/customer-support.ts");
const _temp2 = require("./components/customersupport/v2/customer-support-v2.ts");


const _temp3 = require("./components/faq/faq-search.ts");
const _temp4 = require("./components/faq/faq.ts");

const _temp5 = require("./components/form/mandatory-checkbox.ts");

const _temp6 = require("./components/header/header.ts");

const _temp7 = require("./components/registrationstatusbanner/registrationstatusbanner.ts");

const _temp8 = require("./components/resource-details/_resource-details.ts");

const _temp9 = require("./components/resourceslist/resourceslist.ts");

const _temp10 = require("./components/tabs/tabs.ts");

const _temp11 = require("./components/tipBanner/tipbanner.ts");

module.exports = {
  "ami":   {
    "ami": _temp0
  },
  "customersupport":   {
    "customer-support": _temp1,
    "v2":     {
      "customer-support-v2": _temp2
    }
  },
  "faq":   {
    "faq-search": _temp3,
    "faq": _temp4
  },
  "form":   {
    "mandatory-checkbox": _temp5
  },
  "header":   {
    "header": _temp6
  },
  "registrationstatusbanner":   {
    "registrationstatusbanner": _temp7
  },
  "resource-details":   {
    "_resource-details": _temp8
  },
  "resourceslist":   {
    "resourceslist": _temp9
  },
  "tabs":   {
    "tabs": _temp10
  },
  "tipBanner":   {
    "tipbanner": _temp11
  }
}