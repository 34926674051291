import BadgerAccordion from 'badger-accordion';
const FAQSearch = function (elem) {
  // @ts-ignore
  const self = this;

  // adds badger accordion to the obtained results
  self.appendBadger = () => {
    const accElem = self.searchResultsStage?.querySelector('.aaaem-accordion');
    if (accElem && BadgerAccordion) {
      try {
        const badgerInst = new BadgerAccordion(accElem);

        // timer for the effect to show accordion opening
        setTimeout(() => {
          badgerInst.open(0);
        }, 200);
      } catch (e) {
        console.warn(e, 'accordion failed to load');
      }
    }
  };

  // updates search term placeholders in results
  self.updateSearchTermInResults = searchTerm => {
    if (self.searchTermPlaceholders?.length) {
      Array.from(self.searchTermPlaceholders).forEach(e => {
        if (e && e instanceof HTMLElement) {
          e.innerText = searchTerm;
        }
      });
    }
  };

  // shows no results section.
  self.showNoResults = () => {
    self.elem.classList.add(self.classnames.hasNoResults);
  };

  // appends search results. Manages the DOM state to show and hide results.
  self.showResults = data => {
    if (data) {
      const results = data.querySelector('.accordion');
      const resultCount = results?.querySelectorAll('dt') as HTMLElement[];
      self.elem.classList.remove(self.classnames.loading);
      self.elem.classList.add(self.classnames.showsResultsSection);

      if (resultCount.length) {
        // @TODO: Append search results
        self.elem.classList.add(self.classnames.hasResults);
        self.searchResultsStage.append(results);
        self.appendBadger();
      } else {
        self.showNoResults();
      }
    } else {
      self.elem.classList.remove(self.classnames.loading);
    }
  };

  // hides results and shows
  self.hideResults = e => {
    if (e) {
      e.preventDefault();
    }
    self.elem.classList.remove(self.classnames.loading);
    self.elem.classList.remove(self.classnames.hasResults);
    self.elem.classList.remove(self.classnames.hasNoResults);
    self.elem.classList.remove(self.classnames.showsResultsSection);
    self.searchInpWrapper.classList.remove(self.classnames.inputHasValue);
    if (self.searchInp) {
      self.searchInp.value = '';
    }
  };

  // searches and shows results for the search term entered
  self.search = e => {
    e?.preventDefault();
    const searchTerm = self.searchInp?.value?.trim();
    const pattern = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (searchTerm) {
      window.dataLayer = window.dataLayer ? window.dataLayer : [];
      window.dataLayer.push({
        event: 'faqSearch',
        searchTerm: searchTerm,
      });
      self.elem.classList.remove(self.classnames.hasResults);
      self.elem.classList.remove(self.classnames.hasNoResults);
      self.elem.classList.add(self.classnames.loading);
      self.searchResultsStage.innerHTML = '';

      self.updateSearchTermInResults(searchTerm);
      if (pattern.test(searchTerm)) {
        self.elem.classList.add(self.classnames.hasNoResults);
        self.elem.classList.add(self.classnames.showsResultsSection);
        self.elem.classList.remove(self.classnames.loading);
      } else {
        fetch(`${self.searchPath}?searchItem=${encodeURIComponent(searchTerm)}`)
          .then(function (response) {
            // When the page is loaded convert it to text
            return response.text();
          })
          .then(function (html) {
            var parser = new DOMParser();
            let data = parser.parseFromString(html, 'text/html');
            self.showResults(data);
          })
          .catch(function (err) {
            console.log('Failed to fetch page: ', err);
            self.elem.classList.remove(self.classnames.loading);
          });
      }
    } else {
      self.hideResults();
    }
  };

  // clears the input value, hides the clear input trigger, hides results section
  self.clearInput = e => {
    e?.preventDefault();
    if (self.searchInp) {
      self.searchInp.value = '';
      self.searchInpTouched();
      self.hideResults();
    }
  };

  // shows and hides the clear input based on the value of search input
  self.searchInpTouched = () => {
    if (self.searchInp) {
      const inpVal = self.searchInp.value;
      if (inpVal) {
        self.searchInpWrapper.classList.add(self.classnames.inputHasValue);
      } else {
        self.searchInpWrapper.classList.remove(self.classnames.inputHasValue);
      }
    }
  };

  // append events
  self.appendEvents = () => {
    if (self.searchInp) {
      self.searchInp.addEventListener('input', self.searchInpTouched);
    }

    if (self.searchInpClose) {
      self.searchInpClose.addEventListener('click', self.clearInput);
    }

    if (self.searchForm) {
      self.searchForm.addEventListener('submit', self.search);
    }

    if (self.backToFaqLink) {
      self.backToFaqLink.addEventListener('click', self.hideResults);
    }
  };

  // init variables being used across the file
  self.initVariables = () => {
    self.elem = elem;
    self.searchInp = self.elem?.querySelector(
      '.faq__search-input'
    ) as HTMLInputElement;
    self.searchInpWrapper = self.elem?.querySelector(
      '.faq__search-input-wrapper'
    ) as HTMLInputElement;
    self.searchForm = self.elem?.querySelector(
      '.faq__search-wrapper'
    ) as HTMLInputElement;
    self.searchInpClose = self.elem?.querySelector(
      '.faq__clear-input'
    ) as HTMLElement;
    self.searchResults = self.elem.querySelector(
      '.faq__search-results-wrapper'
    ) as HTMLElement;
    self.searchPath = self.searchResults?.getAttribute('data-search-path');
    self.searchResultsStage = self.elem?.querySelector(
      '.faq__search-results-stage'
    );
    self.searchTermPlaceholders = self.elem?.querySelectorAll(
      '.faq__search-results-search-term'
    ) as HTMLElement[];
    self.backToFaqLink = self.elem?.querySelector(
      '.faq__back-to-search-link'
    ) as HTMLElement;
    self.classnames = {
      inputHasValue: 'faq__search-input-wrapper--has-value',
      loading: 'faq__wrapper--loading',
      hasResults: 'faq__wrapper--has-results',
      hasNoResults: 'faq__wrapper--has-no-results',
      showsResultsSection: 'faq__wrapper--show-results',
    };
  };

  const init = () => {
    self.initVariables();
    self.appendEvents();
  };

  init();
};

const initFAQSearch = function () {
  const faqs = document.querySelectorAll('.faq__wrapper');

  if (faqs?.length) {
    Array.from(faqs).forEach(faq => {
      new FAQSearch(faq);
    });
  }
};

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', initFAQSearch);
} else {
  initFAQSearch();
}
