(() => {
  // @ts-ignore
  function validateForm(el) {
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    // validates form field when passed as a parameter
    self.validateField = (field: HTMLInputElement) => {
      const parentEl = field.closest('div') as HTMLElement;
      const errorMsgEl = parentEl?.querySelector('.ous-form__control-msg');
      const isRequired = parentEl?.getAttribute('data-required');
      const isEmail = field.getAttribute('type') === 'email';
      const val = field.value;
      const isCheckbox = field.classList.contains('ous-form__field--checkbox');

      // select dropdown's default option value is given to be empty string
      let isEmpty = val.length === 0 || val?.trim() === '';

      if (isCheckbox && isRequired) {
        isEmpty = field.checked !== true;
      }

      // resetting form submission error message
      self.formSubmissionErrorMsgEl?.classList.remove(
        self.classnames.formSubmissionErrorShowClass
      );

      if (parentEl) {
        // checking if element has required attribute, and if the value is empty
        // checking if it is an email field and if it does not follow the pattern
        if (
          (isRequired && isEmpty) ||
          (isEmail && !self.emailPattern.test(val))
        ) {
          parentEl.classList.add(self.classnames.errorClass);
          if (errorMsgEl) {
            const msg = isEmpty
              ? parentEl.getAttribute('data-cmp-required-message')
              : parentEl.getAttribute('data-cmp-constraint-message');
            errorMsgEl.textContent = msg || '';
          }
          return;
        }

        parentEl.classList.remove(self.classnames.errorClass);
      }
    };

    // removes the error messages, hides the success state messages
    self.resetForm = () => {
      self.formInput?.forEach((inp: HTMLInputElement) => {
        const parentEl = inp.closest('div');
        const inpElem = inp;
        if (inp instanceof HTMLSelectElement) {
          inpElem.value = ' ';
        } else if (
          inp instanceof HTMLInputElement ||
          inpElem instanceof HTMLTextAreaElement
        ) {
          inpElem.value = '';
        }
        parentEl?.classList.remove(self.classnames.errorClass);
      });

      self.formSubmissionErrorMsgEl?.classList.remove(
        self.classnames.formSubmissionErrorShowClass
      );

      self.formWrapper?.classList.remove(
        self.classnames.formHiddenClass,
        self.classnames.formSubmittingClass
      );

      self.captchaFormEl?.classList.remove(self.classnames.errorClass);
      // @ts-ignore
      grecaptcha?.reset?.(self.captcha);
    };

    self.handleSubmitFail = () => {
      self.formSubmissionErrorMsgEl?.classList.add(
        self.classnames.formSubmissionErrorShowClass
      );

      self.formWrapper?.classList.remove(self.classnames.formSubmittingClass);
    };

    // validates captcha present in the form
    self.validateCaptcha = () => {
      if (self.captchaFormEl && (self.captcha || self.captcha === 0)) {
        // @ts-ignore
        self.captchaVal = grecaptcha.getResponse?.(self.captcha);

        if (self.captchaVal) {
          self.captchaFormEl.classList.remove(self.classnames.errorClass);
        } else {
          self.captchaFormEl.classList.add(self.classnames.errorClass);
        }
      }
    };

    // when the form is submitted, check for all the
    self.submit = event => {
      event.preventDefault();
      self.formInput?.forEach(elem => {
        self.validateField(elem);
      });

      let formHasErrors = self.el.querySelectorAll(
        `.${self.classnames.errorClass}`
      );

      // if every field is valid, then check if captcha is valid
      if (formHasErrors?.length <= 0) {
        self.validateCaptcha();
        formHasErrors = self.el.querySelectorAll(
          `.${self.classnames.errorClass}`
        );
      }

      if (formHasErrors?.length <= 0) {
        let subject = self.el.querySelector('#subject')?.value;
        let inquiry = '';
        if (self.fixedSubject && self.fixedSubject !== '') {
          inquiry = subject;
          subject = self.fixedSubject;
        }

        if (self.endpoint && self.recipientMail) {
          fetch('/libs/granite/csrf/token.json')
            .then(
              e => e.json(),
              error => {
                self.handleSubmitFail(error);
              }
            )
            .then(
              e => {
                const data = {
                  email: self.el.querySelector('#email')?.value,
                  recipient: self.recipientMail,
                  sender: self.sender,
                  language: self.language,
                  subject,
                  inquiry,
                  description: `${
                    self.el.querySelector('#description')?.value
                  } - ${self.el.querySelector('#email')?.value}`,
                  token: self.captchaVal || '',
                };

                self.formWrapper?.classList.add(
                  self.classnames.formSubmittingClass
                );

                fetch(self.endpoint, {
                  method: 'POST',
                  body: JSON.stringify(data),
                  headers: {
                    'CSRF-Token': e.token,
                  },
                })
                  .then(
                    response => {
                      try {
                        return response.json();
                      } catch (error) {
                        self.handleSubmitFail(error);
                        return null;
                      }
                    },
                    error => {
                      self.handleSubmitFail(error);
                    }
                  )
                  .then(resp => {
                    if (resp?.status === 200) {
                      self.formWrapper?.classList.add(
                        self.classnames.formHiddenClass
                      );

                      self.formWrapper?.classList.remove(
                        self.classnames.formSubmittingClass
                      );

                      // adding data to data layer
                      window.dataLayer = window.dataLayer
                        ? window.dataLayer
                        : [];
                      window.dataLayer.push({
                        event: 'contactFormSubmitted',
                        formSubject: subject,
                      });
                    } else {
                      self.handleSubmitFail(e);
                    }
                  })
                  .catch(() => {
                    self.handleSubmitFail();
                  });
              },
              () => {
                self.handleSubmitFail();
              }
            );
        }
      }
    };

    // appends events to the elements in the form
    self.appendEvents = () => {
      // adding events for the focusout event on each input element
      if (self.formInput?.length) {
        self.formInput.forEach(inputField => {
          inputField.addEventListener('focusout', () => {
            self.validateField(inputField);
          });

          if (inputField instanceof HTMLSelectElement) {
            inputField.addEventListener('change', () => {
              self.validateField(inputField);
            });
          }
        });
      }

      // event for when the submit button is clicked
      self.formSubmit?.addEventListener('click', self.submit);

      // On modal close, reset the form, the errors and the success states
      if (window.Bus) {
        window.Bus?.on('emu-modal:close', e => {
          if (e.id === 'customer-support-modal') {
            queueMicrotask(self.resetForm);
          }
        });
      }
    };

    self.initVariables = () => {
      self.el = el;
      self.formInput = self.el.querySelectorAll(
        '.ous-form__controls-container .ous-form__field'
      ) as NodeListOf<HTMLInputElement>;
      self.formSubmit = self.el.querySelector('.ous-form__submit');
      self.privacyCheckbox = self.el.querySelector('.privacy-checkbox');
      self.formWrapper = self.el.closest('.modal-content');
      self.formSubmissionErrorMsgEl = self.formWrapper?.querySelector(
        '.customer-support-modal__error-msg'
      );

      self.captchaEl = self.el.querySelector('.support-captcha');
      self.captchaFormEl = self.captchaEl?.closest('.ous-form__control');

      self.classnames = {
        errorClass: 'ous-form__control--has-error',
        formHiddenClass: 'modal-content--form-hidden',
        formSubmissionErrorShowClass: 'customer-support-modal__error-msg--show',
        formSubmittingClass: 'modal-content--form-submitting',
      };
      self.emailPattern =
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9,-]+\.(?:com|net|org|COM|NET|ORG)$/; // pattern from existing site
      self.endpoint = self.el.querySelector(
        '.customer-support-endpoint'
      )?.value;
      self.recipientMail = self.el.querySelector(
        '.customer-support-recipient'
      )?.value;
      self.sender = self.el.querySelector('.customer-support-sender')?.value;
      self.language = self.el.querySelector(
        '.customer-support-language'
      )?.value;
      self.fixedSubject = self.el.querySelector(
        '.customer-support-fixed-subject'
      )?.value;
    };

    self.initCaptcha = () => {
      // @ts-ignore
      if (grecaptcha && self.captchaEl) {
        // @ts-ignore
        grecaptcha?.ready?.(() => {
          // @ts-ignore
          self.captcha = grecaptcha.render(self.captchaEl, {
            expiredCallback() {
              self.validateCaptcha();
            },
            callback() {
              self.validateCaptcha();
            },
            errorCallback() {
              self.validateCaptcha();
            },
          });
        });
      }
    };

    const init = () => {
      self.initVariables();
      self.appendEvents();
      // @ts-ignore
      if (window.isCaptchaReady) {
        self.initCaptcha();
      } else {
        window.addEventListener('captcha-loaded', self.initCaptcha);
      }
    };

    init();
  }
  const initValidateForm = () => {
    /* eslint-disable-next-line no-restricted-properties */
    const el = document.querySelector('.contact-customer-support');
    if (el) {
      /* eslint-disable-next-line no-new,new-cap */
      new validateForm(el);
    }
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', initValidateForm);
  } else {
    initValidateForm();
  }
})();
