/* eslint-disable @typescript-eslint/naming-convention, import/extensions, no-restricted-properties, no-underscore-dangle, max-len */

function tipBannerLoad() {
  const tipBanner = document.querySelectorAll(
    '.tip-banner'
  ) as NodeListOf<HTMLElement>;
  if (tipBanner) {
    tipBanner.forEach(el => {
      const closeButton = el.querySelector(
        '.tip-banner__close'
      ) as HTMLButtonElement;

      if (closeButton) {
        closeButton?.addEventListener('click', () => {
          el?.classList?.add('tip-banner--hidden');
        });
      }
    });
  }
}

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', tipBannerLoad);
} else {
  tipBannerLoad();
}
