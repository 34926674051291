const FAQ = function (elem) {
  // @ts-ignore
  const self = this;

  // when tab is changed, re-calculate the accordion heights and open the first accordion tile
  self.tabChanged = e => {
    const activeTab = e?.detail?.content;
    if (activeTab) {
      self.collapseAllInactiveAccordions();
      const activeAccordion = activeTab.querySelector('.aaaem-accordion');
      const activeAccordionId = activeAccordion?.getAttribute('id');
      if (activeAccordionId) {
        const accInst = window._badgerInstances?.[activeAccordionId];
        accInst?.init();
        const timer = setTimeout(() => {
          const firstAccHeader = accInst?.headers?.[0];
          const firstBadgerOpen =
            firstAccHeader?.classList?.contains('-ba-is-active');
          if (!firstBadgerOpen && firstAccHeader) {
            accInst?.open(0);
          }
          clearTimeout(timer);
        }, 200);
      }
    }
  };

  // By default every accordion shows the first one expanded. But, coz the accordion is present inside tabs for FAQ, the height is not calculated well
  // Also the state of the accordion is being saved when tabs is switched. Ex: Tab 1 shows previously expanded accordions, when switched back from another tab and the requirement shows that only first accordion must be opened when switching tabs
  // This function hides all the opened accordions inside the inactive tabs.
  // The heights will be calculated when tab is switched. Also the expansion takes place after the tab is switched
  self.collapseAllInactiveAccordions = () => {
    const inactiveTabs = self.elem.querySelectorAll(
      '.aaaem-tabs__tabpanel[hidden="hidden"]'
    ) as HTMLElement[];
    if (inactiveTabs?.length) {
      Array.from(inactiveTabs).forEach(tab => {
        const acc = tab?.querySelector('.aaaem-accordion');
        const accId = acc?.getAttribute('id');
        if (accId) {
          const accInst = window._badgerInstances?.[accId];
          accInst?.closeAll?.();
        }
      });
    }
  };

  // append events
  self.appendEvents = () => {
    if (self.tabs) {
      window.addEventListener('tabby', self.tabChanged);
    }

    // making sure that the height of the accordion content gets appropriate height when expanded and collapsed
    window.Bus?.on('emu-accordion:toggle', ({ id, itemClicked }) => {
      const badgerInstance = window._badgerInstances?.[id];
      const accEl = document.querySelector(`#${id}`);
      if (accEl && badgerInstance && itemClicked?.id) {
        const itemClickedButton = accEl
          .querySelector(`#${itemClicked.id} .js-badger-accordion-header`)
          ?.getAttribute('aria-controls');
        const accordionContent = accEl.querySelector(`#${itemClickedButton}`);

        if (itemClicked.opened) {
          const itemClickedTitle = itemClicked.title;
          window.dataLayer = window.dataLayer ? window.dataLayer : [];
          window.dataLayer.push({
            event: 'faqOpened',
            faqTitle: itemClickedTitle,
          });
        }

        if (accordionContent) {
          badgerInstance.calculatePanelHeight?.(accordionContent);
        }
      }
    });

    // when window is resized, re-calculating accordion panels' height
    window.addEventListener('resize', () => {
      const badgerInstances =
        window._badgerInstances && Object.values(window._badgerInstances);
      if (badgerInstances?.length) {
        badgerInstances.forEach(inst => {
          inst?.calculateAllPanelsHeight?.();
        });
      }
    });
  };

  // init variables being used across the file
  self.initVariables = () => {
    self.elem = elem;
    self.tabs = self.elem.querySelector(
      '.faq__tabs-wrapper .tabs'
    ) as HTMLElement[];
  };

  const init = () => {
    self.initVariables();
    self.appendEvents();
    self.collapseAllInactiveAccordions();
  };

  init();
};

const initFAQ = function () {
  const faqs = document.querySelectorAll('.faq__wrapper');

  if (faqs?.length) {
    Array.from(faqs).forEach(faq => {
      new FAQ(faq);
    });
  }
};

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', initFAQ);
} else {
  initFAQ();
}
