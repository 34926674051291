// eslint-disable-next-line @typescript-eslint/naming-convention
function ResourcesList(el: HTMLElement) {
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const self = this;

  // displays the results based on the selected filters
  self.displayResults = () => {
    self.resourceItems?.forEach(itemEl => {
      const itemData = itemEl?.getAttribute(self.filterAttrName) ?? '';

      // Display All Results
      if (self.activeFilters?.indexOf('all') >= 0) {
        itemEl.classList.remove('hide');
      } else if (self.activeFilters?.includes(itemData)) {
        // Hide result when current filer is not 'all'
        itemEl.classList.remove('hide');
      } else {
        itemEl.classList.add('hide');
      }
    });
  };

  self.manageButtonsState = () => {
    self.filterButtons.forEach(btn => {
      const filter = btn.getAttribute(self.filterAttrName);
      if (self.activeFilters.indexOf(filter) >= 0) {
        btn.classList.add('is-active');
      } else {
        btn.classList.remove('is-active');
      }
    });
  };

  // for a given filter name, and if it is previously active, updates the active filters array
  // when all is already selected and clicked again, do nothing.
  // when all is selected, show all items
  // when a filter is selected, push the filter name to the list
  // when a filter is deselected, remove the filter name from the list

  self.updateActiveFilters = (isPreviouslyActive, filterName) => {
    if (filterName === self.selectAllFilterName) {
      if (isPreviouslyActive) {
        return;
      } else {
        self.activeFilters = [self.selectAllFilterName];
      }
    } else {
      if (isPreviouslyActive) {
        self.activeFilters = self.activeFilters?.filter(
          tag => tag !== filterName
        );
      } else {
        self.activeFilters = self.activeFilters?.filter(tag => {
          return tag !== self.selectAllFilterName;
        });
        self.activeFilters.push(filterName);
      }
    }

    // when no filter is selected, push all as a selected filter
    if (self.activeFilters.length === 0) {
      self.activeFilters = [self.selectAllFilterName];
    }
  };

  self.appendEvents = () => {
    // Handle Click on Resource Filter Tag
    self.filterButtons?.forEach(btn => {
      btn.addEventListener('click', function () {
        //@ts-ignore
        const curBtn = this;
        const isPreviouslyActive = curBtn.classList.contains('is-active');
        const curFilter = curBtn.getAttribute(self.filterAttrName);

        self.updateActiveFilters(isPreviouslyActive, curFilter);
        self.manageButtonsState();
        self.displayResults();
      });
    });
  };

  // initialize all the variables and elements used in this file
  self.initVariables = () => {
    self.filtersEl = el.querySelector(
      '.resources-list__filters-wrapper'
    )! as HTMLElement;
    self.filterButtons = el.querySelectorAll('.resources-list__trigger');
    self.resourceItems = el.querySelectorAll('.resources-list__item');
    self.selectAllBtn = self.filtersEl?.querySelector(
      '.resources-list__filter--all .resources-list__trigger'
    );

    self.filterAttrName = 'data-filter';
    self.selectAllFilterName = self.selectAllBtn?.getAttribute(
      self.filterAttrName
    );
    self.activeFilters = [self.selectAllFilterName];
  };

  self.init = function () {
    self.initVariables();
    self.appendEvents();
    self.displayResults();
  };

  self.init();
}

function resourceListOnLoad() {
  // eslint-disable-next-line no-restricted-properties
  const el = document.querySelector('.resourceslist') as HTMLElement;
  const isBlurredRes = el?.querySelector('.resources-list__wrapper--blur');

  if (el && !isBlurredRes) {
    new ResourcesList(el);
  }
}

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', resourceListOnLoad);
} else {
  resourceListOnLoad();
}
