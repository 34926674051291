(() => {
  const ResourceMediaPlyr = function (el) {
    //@ts-ignore
    const self = this;

    // for a given src, returns type of the media the src belongs to
    const getTypeOfMedia = (mediaSrc = '') => {
      if (mediaSrc.includes('vimeo.com')) {
        return 'vimeo';
      } else if (mediaSrc.includes('youtube')) {
        return 'youtube';
      } else {
        const matchingVideoFormat = self.knownVideoFormats.filter(format =>
          mediaSrc.includes(`.${format}`)
        );
        if (matchingVideoFormat?.length) {
          return `video/${matchingVideoFormat[0]}`;
        }

        const matchingAudioFormat = self.knownAudioFormats.filter(format =>
          mediaSrc.includes(`.${format}`)
        );
        if (matchingAudioFormat?.length) {
          return `audio/${matchingAudioFormat[0]}`;
        }
      }
    };

    // for a given src, returns `player.source.sources` object
    // `player.source.sources` matches the object from https://github.com/sampotts/plyr#the-source-setter
    const getPlayerSourcesObj = src => {
      if (src) {
        const srcObj: {
          src: string;
          type?: string;
          provider?: string;
        } = {
          src,
        };

        // `type` should be used for HTML5 Video/Audio. `provider` should be used for youtube/vimeo
        const mediaType = getTypeOfMedia(src);
        if (mediaType?.includes('audio/') || mediaType?.includes('video/')) {
          srcObj.type = mediaType;
        } else {
          srcObj.provider = mediaType;
        }
        return srcObj;
      }

      return {};
    };

    // generates source object that is to be fed to the "Plyr" sources via source setter
    // https://github.com/sampotts/plyr#the-source-setter for more info.
    const generatePlayerSrcObj = (el: HTMLElement) => {
      const videoDetails = el.dataset;
      // src will be src or fallback src
      const mediaSrc = videoDetails.src || videoDetails.fallbackSrc || '';
      const fallbackMediaSrc =
        (videoDetails.src && videoDetails.fallbackSrc) || '';

      const playerSrcObj: {
        title: string;
        type?: 'video' | 'audio';
        sources?: {
          src?: string;
          type?: string;
          provider?: string;
        }[];
      } = {
        title: videoDetails.title || '',
        type: 'video',
        sources: [
          getPlayerSourcesObj(mediaSrc),
          getPlayerSourcesObj(fallbackMediaSrc),
        ],
      };

      playerSrcObj.sources = playerSrcObj.sources?.filter(item => item.src);

      if (playerSrcObj.sources?.[0]?.type?.includes('audio/')) {
        playerSrcObj.type = 'audio';
      }

      return playerSrcObj;
    };

    // for a given index, changes the player to show corresponding source in the playlist
    // also checks whether vimeo is supported or not, and then shows fallback video
    self.changeMedia = (activeIndex = 0) => {
      if (self.activeIndex !== activeIndex) {
        const activeMediaObj = self.playlist[activeIndex];
        self.activeIndex = activeIndex;
        const { sources } = activeMediaObj;
        self.mediaEl.classList.add('resource-details__media--faded');

        const setSrcObj = () => {
          // setting ready event so that as soon as the source is changed, this kicks in.
          self.player.once('ready', () => {
            // Plyr does not provide events that shows whether the frames of video are loaded or not
            // necessary timer so that the video gets ready before hitting autoplay
            // working fine for a slow 3g network throttle too
            const readyTimer = setTimeout(() => {
              self.player.play();
              playerReadyEvents();
              self.mediaEl.classList.remove('resource-details__media--faded');
              clearTimeout(readyTimer);
            }, 400);
          });

          // changing player source
          self.player.source = activeMediaObj;
        };

        // For a vimeo video, if there is a fallback video, checking whether vimeo is supported, and then changing the player source.
        // if there is a fallback no. of sources will be greater than one.
        if (
          sources?.length > 1 &&
          sources[0].provider === 'vimeo' &&
          sources[0].src
        ) {
          fetch(
            `https://vimeo.com/api/oembed.json?url=${encodeURIComponent(
              sources[0].src
            )}`
          )
            .then(
              resp => resp.json(),
              () => null
            )
            .then(setSrcObj, () => {
              // removing vimeo src from the playlist if the vimeo video is not available/supported. Removing so that it does not have to process it the next time.
              activeMediaObj.sources = activeMediaObj.sources.filter(
                //@ts-ignore
                (item, i) => i > 0
              );
              setSrcObj();
            });
        } else {
          setSrcObj();
        }
      }
    };

    // when the playlist button in controls is clicked, show/hide the playlist
    const appendTogglePlaylistEvent = () => {
      const playlistBtn = self.mediaEl?.querySelector(
        '.plyr__control--playlist'
      );

      const playlistEl = self.mediaEl?.querySelector(
        '.plyr__controls .plyr__playlist'
      );

      // Toggle PlayList
      playlistBtn?.addEventListener('click', () => {
        // making sure that player exits fullscreen when playlist is shown
        if (
          playlistEl?.classList.contains('hidden') &&
          self.player?.fullscreen?.active
        ) {
          self.player.fullscreen?.exit();
        }

        playlistEl?.classList.toggle('hidden');
      });

      // when full screen is enabled, hiding the playlist
      self.player?.on('enterfullscreen', () => {
        playlistEl?.classList.add('hidden');
      });
    };

    // manages events of the playlist click, and manages the active state of the playlist
    const appendPlaylistEvents = () => {
      const playlistAnchors = self.mediaEl.querySelectorAll(
        '.plyr__controls .plyr__playlist .plyr__playlist-item'
      );

      if (playlistAnchors?.length) {
        playlistAnchors.forEach((listItem, i) => {
          // whenever items from the playlist is clicked, change the Plyr Source
          listItem.addEventListener('click', e => {
            e.preventDefault();
            self.changeMedia(i);
          });

          // whenever items from the playlist is clicked, change the active state of the anchor
          if (i === self.activeIndex) {
            listItem.classList.add('active');
          } else {
            listItem.classList.remove('active');
          }
        });
      }
    };

    // manages full volume button events. The ones given by Plyr does not have full volume
    const appendVolumeButtonEvents = () => {
      const fullVolumeBtn = self.mediaEl?.querySelector(
        '.plyr__control--full-volume'
      );
      fullVolumeBtn?.addEventListener('click', () => {
        if (self.player) {
          self.player.volume = 1;
        }
      });
    };

    // Plyr does not have a control for the stop event. Adding custom control to achieve this
    const appendStopButtonEvents = () => {
      const stopBtn = self.mediaEl?.querySelector('.plyr__control--stop');
      stopBtn?.addEventListener('click', () => {
        self.player?.stop();
      });
    };

    // when video is completed, the player should trigger stop method
    const appendOnVideoComplete = () => {
      self.player?.once('ended', () => {
        self.player?.stop();
      });
    };

    // events that should happen after the player is ready
    // triggers once after every player source change via changeMedia
    const playerReadyEvents = () => {
      // handle playlist items
      appendPlaylistEvents();

      // Toggle playlist
      appendTogglePlaylistEvent();

      // handle volume button
      appendVolumeButtonEvents();

      // handle stop button
      appendStopButtonEvents();

      // handle video complete event
      appendOnVideoComplete();
    };

    // a HTML UL element contains the list of videos that are to be played.
    // this populates the `self.playlist` variable with array of objects with each one matching the structure of `player.source` from https://github.com/sampotts/plyr#the-source-setter
    const extractPlaylist = () => {
      const listElems = self.playlistEl?.querySelectorAll('li');
      if (listElems?.length) {
        listElems.forEach(el => {
          self.playlist.push(generatePlayerSrcObj(el));
        });
      }
    };

    // initiates the player and append events to the custom controls
    const initiatePlayer = () => {
      self.player = new window.Plyr(self.playerElem, {
        controls: self.playerControlsEl.innerHTML,
        hideControls: false,
      });
    };

    // appending events to the elements on the page
    const appendEvents = () => {
      // for the first time, when user clicks on the image,
      // extract playlist, initiate player, hide the image and show the video with first video in the list
      self.mediaTrigger?.addEventListener('click', () => {
        extractPlaylist();
        initiatePlayer();
        self.mediaEl.classList.add('resource-details__media--show-video');

        // setting the first video to show default
        self.changeMedia(0);
      });
    };

    // initiating the variables that will be used across the file
    const initVariables = () => {
      self.playerElem = el;
      self.mediaEl = el.closest('.resource-details__media');
      self.playlistEl = self.mediaEl?.querySelector('.plyr__playlist');
      self.mediaTrigger = self.mediaEl?.querySelector(
        '.resource-details__media-trigger'
      );
      self.playerControlsEl = self.mediaEl.querySelector(
        '.resource-details__video-controls'
      );

      self.playlist = [];
      self.activeIndex = null;
      self.knownVideoFormats = ['mp4', 'webm'];
      self.knownAudioFormats = ['mp3', 'ogg'];
    };

    const init = () => {
      initVariables();
      appendEvents();
    };

    init();
  };

  // data layer event triggering for resource view
  const handleDataLayerEvents = () => {
    const resourceTitle = document.querySelector(
      '.resource-details__wrapper .resource-details__title'
    ) as HTMLHeadingElement;

    const resourceTitleText = resourceTitle?.innerText;
    if (resourceTitleText) {
      window.dataLayer = window.dataLayer ? window.dataLayer : [];
      window.dataLayer.push({
        event: 'viewResource',
        resourceTitle: resourceTitleText,
      });
    }
  };

  const init = () => {
    const playerPlaceholder = document.querySelector(
      '#resource-details__plyr-placeholder'
    );
    if (playerPlaceholder) {
      new ResourceMediaPlyr(playerPlaceholder);
    }

    // on page load of resource details page, add data to data layer
    const resourceDetailsPage = document.querySelector(
      '.resource-details__wrapper'
    );
    if (resourceDetailsPage) {
      handleDataLayerEvents();
    }
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
