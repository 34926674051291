function regBannerLoad() {
  const el = document.querySelector(
    '.registrationstatusbanner-wrapper'
  ) as HTMLElement;

  if (el) {
    const closeButton = document.querySelectorAll(
      '.registration-status-banner__btn-close'
    ) as NodeList;

    closeButton.forEach(btn => {
      (<HTMLButtonElement>btn)?.addEventListener('click', () => {
        el.classList.add('registration-status-banner--hidden');
      });
    });
  }
}

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', regBannerLoad);
} else {
  regBannerLoad();
}
